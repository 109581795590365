import '../stylesheets/not_logged_in.scss'

// console.debug('loading js: app/javascript/packs/not_logged_in.js')

require('@rails/ujs').start()
require('@rails/activestorage').start()

// toggle input fields between text and password type
// ALL visibility icons and ALL password fields on the page are toggled
// the toggling is based on the initial state of the event target (an icon)
window.togglePasswordVisibility = (event) => {
  const toggleIcon = event.target
  // need to cache this value as we're gonna change it in a list!
  const visibilityIsOn = toggleIcon.classList.contains('visibility-on')
  const allInputs = document.querySelectorAll('.visibility-target')
  const allIcons = document.querySelectorAll('.visibility-icon')
  for (const inputField of allInputs) {
    if (visibilityIsOn) {
      inputField.type = 'text'
    } else {
      inputField.type = 'password'
    }
  }
  for (const icon of allIcons) {
    if (visibilityIsOn) {
      icon.classList.remove('visibility-on')
      icon.classList.add('visibility-off')
    } else {
      icon.classList.remove('visibility-off')
      icon.classList.add('visibility-on')
    }
  }
}
